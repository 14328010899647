import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "wondeFields",
    "wondeId",
    "wondeToggle",
    "syncButton",
  ];

  toggle(event) {
    const isWondeEnabled = event.target.checked;

    this.wondeIdTarget.required = isWondeEnabled;
    this.wondeFieldsTarget.classList.toggle("hidden");
    this.syncButtonTarget.classList.toggle("hidden");
  }

  disableWonde(event) {
    const isWondeEmpty = event.target.value == "";

    if (isWondeEmpty) {
      this.wondeToggleTarget.checked = false;
      this.toggle(event);
    }
  }
}
