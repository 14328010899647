import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "studentsOnRoleField",
    "numberOfStudentsInput"
  ];

  toggle(event) {
    const isStudentSurveysAddonEnabled = event.target.checked;

    this.numberOfStudentsInputTarget.required = isStudentSurveysAddonEnabled;
    this.studentsOnRoleFieldTarget.classList.toggle("hidden");
  }
}
