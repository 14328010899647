import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["newField", "fieldInputTemplate", "field", "maxLocationsReached"]
  static values = { max: Number, schoolType: String }

  // When the add button is clicked in the locations new view or departments
  // new view, a new field is added to the form.
  addField(event) {
    if (this.schoolTypeValue === 'multi_academy_trust' && this.maxValue <= this.fieldTargets.length) {
      let classList = this.maxLocationsReachedTarget.classList;
      if (classList.contains('hidden')) { classList.remove('hidden'); }
      return
    }
    event.preventDefault()
    var content = this.fieldInputTemplateTarget.innerHTML.replace(/UNIQUE_ID/g, new Date().valueOf())
    this.newFieldTarget.insertAdjacentHTML('beforebegin', content)
  }

  // When the remove button on a location or department field is clicked,
  // the field is removed from the form.
  removeField(event) {
    event.preventDefault()
    let item = event.target.closest(".dynamic-field")
    item.parentNode.removeChild(item)

    if (this.schoolTypeValue === 'multi_academy_trust' && this.maxValue > this.fieldTargets.length) {
      let classList = this.maxLocationsReachedTarget.classList;
      if (!classList.contains('hidden')) { classList.add('hidden'); }
    }
  }
}
