import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'form',

    // School Type Section
    'stateSchool',
    'nonMaintainedSchool',
    'multiAcademyTrust',

    // MAT Number and Error
    'numberOfTrusts',
    'minNumOfTrusts',

    // Surveying Choice Section
    'surveyingChoiceRadioButtonStaffAndStudents',
    'surveyingChoiceRadioButtonStaffOnly',

    // Errors Section
    'errorsContainerStaff',
    'errorsContainerStudent',

    // Labels for Enrollments
    'labelContainerStaff',
    'labelContainerStudents',
    'maintainedSchoolCost',
    'nonMaintainedSchoolCost',
    'multiAcademyTrustCost',
    'maintainedStudentSurveyCost',
    'nonMaintainedStudentSurveyCost',

    // Enrolment Number Section - Staff
    'enrolmentNumberContainerStaff',
    'enrolmentNumberInputStaff',
    'enrolmentCostContainerStaff',

    // Enrolment Number Section - Students
    'enrolmentNumberContainerStudents',
    'enrolmentNumberInputStudents',
    'enrolmentCostContainerStudents',

    // Calculate
    'calculateCostButton',

    // Enrollment Cost
    'numberOfStaffCost',
    'numberOfStudentsCost',

    'totalCost',

    // These are in "what's included" partial
    'whatsIncludedStaffAndStudents',
    'whatsIncludedStaffOnly',

    'submitButton',
  ]

  onClickStateSchool(event) {
    event.preventDefault();
    this.toggleSchoolPrice('maintained-school');
    this.hideAllErrors();
    this.resetAllNumbers();
    this.disableSubmitButton();
  };

  onClickNonMaintainedSchool(event) {
    event.preventDefault();
    this.toggleSchoolPrice('non-maintained-school');
    this.hideAllErrors();
    this.resetAllNumbers();
    this.disableSubmitButton();
  };

  onClickMultiAcademyTrust(event) {
    event.preventDefault();
    this.toggleSchoolPrice('multi-academy-trust');
    this.hideAllErrors();
    this.resetAllNumbers();
    this.disableSubmitButton();
  };

  // Non Maintained / International Schools pay double the price per subscription than the
  // Maintained schools / each MAT setting
  // This function is to toggle the price in the "Labels for Enrolments"
  toggleSchoolPrice(schoolType) {
    if (schoolType == 'non-maintained-school') {
      this.nonMaintainedSchoolCostTarget.classList.remove('hidden');
      this.maintainedSchoolCostTarget.classList.add('hidden');
      this.multiAcademyTrustCostTarget.classList.add('hidden');

      this.nonMaintainedStudentSurveyCostTarget.classList.remove('hidden');
      this.maintainedStudentSurveyCostTarget.classList.add('hidden');
    } else if ( schoolType == 'maintained-school' )  {
      this.nonMaintainedSchoolCostTarget.classList.add('hidden');
      this.maintainedSchoolCostTarget.classList.remove('hidden');
      this.multiAcademyTrustCostTarget.classList.add('hidden');

      this.nonMaintainedStudentSurveyCostTarget.classList.add('hidden');
      this.maintainedStudentSurveyCostTarget.classList.remove('hidden');
    } else {
      this.nonMaintainedSchoolCostTarget.classList.add('hidden');
      this.maintainedSchoolCostTarget.classList.add('hidden');
      this.multiAcademyTrustCostTarget.classList.remove('hidden');

      this.nonMaintainedStudentSurveyCostTarget.classList.add('hidden');
      this.maintainedStudentSurveyCostTarget.classList.remove('hidden');
    }
  };

  onClickSurveyingChoiceRadioButtonStaffAndStudents(event) {
    event.preventDefault();
    this.hideAllErrors();
    this.resetAllNumbers();
    this.showStudentSection();
    this.disableSubmitButton();
    this.showStudentSurveyBulletPoint();
  };

  onClickSurveyingChoiceRadioButtonStaffOnly(event) {
    event.preventDefault();
    this.hideAllErrors();
    this.resetAllNumbers();
    this.hideStudentSection();
    this.disableSubmitButton();
    this.hideStudentSurveyBulletPoint();
  };

  // When a user clicks the calculate cost button, a request is made to
  // the server for the price and then the page is updated with the response.
  onClickCalculateCostButton(event) {
    event.preventDefault();

    const numberOfTrusts = this.numberOfTrustsTarget.value;
    const numberOfStaff = this.enrolmentNumberInputStaffTarget.value;
    const numberOfStudents = this.enrolmentNumberInputStudentsTarget.value;

    // Show errors and return if form is invalid
    if (!this.validateForm(numberOfTrusts, numberOfStaff, numberOfStudents)) { return; }

    if (this.surveyingChoiceRadioButtonStaffOnlyTarget.checked == 1) {
      this.calculateStaffCost(event);
    }
    if (this.surveyingChoiceRadioButtonStaffAndStudentsTarget.checked == 1) {
      this.calculateStudentAndStaffCost(event);
    }
    return;
  };

  // Returns false if we're missing any of the required values
  // True otherwise
  validateForm(numberOfTrusts, numberOfStaff, numberOfStudents) {
    let formValid = true;

    // Number of Trusts valid?
    if (this.multiAcademyTrustTarget.checked == 1 && numberOfTrusts < 1) {
      this.toggleMatError(true);
      formValid = false;
    } else {
      this.toggleMatError(false);
    }

    // Number of Students valid?
    if (this.surveyingChoiceRadioButtonStaffAndStudentsTarget.checked == 1 && numberOfStudents < 1) {
      this.toggleStudentError(true);
      formValid = false;
    } else {
      this.toggleStudentError(false);
    }

    // Number of Staff valid?
    if (numberOfStaff < 1) {
      this.toggleStaffError(true);
      formValid = false;
    } else {
      this.toggleStaffError(false);
    }

    return formValid;
  };

  toggleMatError(showError) {
    if (showError === true) {
      this.minNumOfTrustsTarget.classList.remove('hidden');
    } else {
      this.minNumOfTrustsTarget.classList.add('hidden');
    }
  };

  toggleStaffError(showError) {
    if (showError === true) {
      this.errorsContainerStaffTarget.classList.remove('hidden');
    } else {
      this.errorsContainerStaffTarget.classList.add('hidden');
    }
  };

  toggleStudentError(showError) {
    if (showError === true) {
      this.errorsContainerStudentTarget.classList.remove('hidden');
    } else {
      this.errorsContainerStudentTarget.classList.add('hidden');
    }
  };

  hideAllErrors() {
    this.errorsContainerStaffTarget.classList.add('hidden');
    this.errorsContainerStudentTarget.classList.add('hidden');
    this.minNumOfTrustsTarget.classList.add('hidden');
  };

  resetAllNumbers() {
    this.resetStylesForCost();
    this.resetNumberOfTrusts();
    this.resetNumberOfStudents();
    this.resetNumberOfStaff();
  };

  resetNumberOfTrusts() {
    this.numberOfTrustsTarget.value = null;
  };

  resetNumberOfStaff() {
    this.enrolmentNumberInputStaffTarget.value = null;
  };

  resetNumberOfStudents() {
    this.enrolmentNumberInputStudentsTarget.value = null;
  };

  hideStudentSection() {
    this.toggleStudentSection(false);
  };

  showStudentSection() {
    this.toggleStudentSection(true);
  };

  toggleStudentSection(showStudentSection){
    if (showStudentSection === true) {
      this.labelContainerStudentsTarget.classList.remove('hidden');
      this.enrolmentNumberContainerStudentsTarget.classList.remove('hidden');
      this.enrolmentCostContainerStudentsTarget.classList.remove('hidden');
      this.enrolmentNumberInputStudentsTarget.required = true;
    } else {
      this.labelContainerStudentsTarget.classList.add('hidden');
      this.enrolmentNumberContainerStudentsTarget.classList.add('hidden');
      this.enrolmentCostContainerStudentsTarget.classList.add('hidden');
      this.enrolmentNumberInputStudentsTarget.required = false;
    }
  };

  // For What's included section
  // ------------------------------
  hideStudentSurveyBulletPoint() {
    this.toggleStudentSurveyBulletPoint(false);
  };

  showStudentSurveyBulletPoint() {
    this.toggleStudentSurveyBulletPoint(true);
  };

  toggleStudentSurveyBulletPoint(showStudentSurveyBulletPoint) {
    if (showStudentSurveyBulletPoint) {
      this.whatsIncludedStaffAndStudentsTargets.forEach((el) => el.classList.remove('hidden'));
      this.whatsIncludedStaffOnlyTargets.forEach((el) => el.classList.add('hidden'));
    } else {
      this.whatsIncludedStaffAndStudentsTargets.forEach((el) => el.classList.add('hidden'));
      this.whatsIncludedStaffOnlyTargets.forEach((el) => el.classList.remove('hidden'));
    }
  }
  // ------------------------------

  async calculateStaffCost() {
    // Fetch the cost of the membership
    const response = await fetch('/memberships/calculate_staff_survey_cost.json', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      body: new FormData(this.formTarget)
    });
    const parsedResponse = await response.json();

    // Update the Staff and total cost
    const cost = this.convertPenceToPounds(parsedResponse.cost);
    this.numberOfStaffCostTarget.classList.remove('text-gray-400');
    this.numberOfStaffCostTarget.innerHTML = `£${cost}`;
    this.totalCostTarget.innerHTML = `£${cost}`;

    // Enable the submit button
    this.enableSubmitButton();
  }

  async calculateStudentAndStaffCost() {
    // Fetch the cost of the student surveys
    const response = await fetch('/memberships/calculate_staff_and_student_survey_cost.json', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      body: new FormData(this.formTarget)
    });
    const parsedResponse = await response.json();

    // Update Staff Surveys cost
    this.numberOfStaffCostTarget.classList.remove('text-gray-400');
    this.numberOfStaffCostTarget.innerHTML = `£${this.convertPenceToPounds(parsedResponse.staffCost)}`;

    // Update Student Surveys cost
    this.numberOfStudentsCostTarget.classList.remove('text-gray-400');
    this.numberOfStudentsCostTarget.innerHTML = `£${this.convertPenceToPounds(parsedResponse.studentCost)}`;

    // Update total cost
    const totalCost = this.convertPenceToPounds(parsedResponse.staffCost + parsedResponse.studentCost)
    this.totalCostTarget.innerHTML = `£${totalCost}`;

    // Enable the submit button
    this.enableSubmitButton();
  }

  // Takes pence and converts it to pounds.
  // If the pounds value is a integer, then it returns the value as an integer.
  convertPenceToPounds(costInPence) {
    if (costInPence % 100 == 0) {
      return costInPence / 100;
    } else {
      return Number.parseFloat(costInPence / 100).toFixed(2);
    }
  }

  enableSubmitButton() {
    const button = this.submitButtonTarget;
    // Enable the button
    button.disabled = false;
    // Change the styling
    const classList = button.classList;
    classList.remove('bg-grey-medium-bg');
    classList.add('blue-klein');
  }

  disableSubmitButton() {
    const button = this.submitButtonTarget;
    // Disabled the button
    button.disabled = true;
    // Change the styling
    const classList = button.classList;
    classList.add('bg-grey-medium-bg');
    classList.remove('blue-klein');
  }

  // Reverts the HTML and CSS to the orginal content and styles
  // for the Cost section.
  resetStylesForCost() {
    // For Staff Cost
    this.numberOfStaffCostTarget.classList.add('text-gray-400');
    this.numberOfStaffCostTarget.innerHTML = '£';

    // For Student cost
    this.numberOfStudentsCostTarget.classList.add('text-gray-400');
    this.numberOfStudentsCostTarget.innerHTML = '£';

    // For total cost
    this.totalCostTarget.innerHTML = '';
  }
}
