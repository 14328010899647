import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { reverseScore: Boolean }
  static targets = ["childQuestionContainer", "parentQuestion", "childQuestion"]

  connect() {
    this.parentQuestionTargets.forEach((elem) => {
      if (elem.checked) {
        this.toggle(elem);
      }
    });
  }

  toggle(e) {
    let answers;
    if (this.reverseScoreValue === true) {
      answers = ['agree', 'strongly_agree'];
    }
    else {
      answers = ['disagree', 'strongly_disagree'];
    }

    if (answers.includes(e.currentTarget?.value || e.value)) {
      this.childQuestionContainerTarget.classList.remove("hidden");
    }
    else {
      this.childQuestionContainerTarget.classList.add("hidden");
      this.childQuestionTargets.forEach((elem) => 
        elem.checked = false
      );
    }
  }
}
